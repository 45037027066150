<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    :width="900">
    <base-card>
      <v-toolbar :color="$vuetify.theme.dark ? 'dark' : 'primary'">
        <v-toolbar-title
          class="white--text"
          v-text="$t('order.createLinkToSubscription')"
        />
        <v-spacer />
        <v-btn
          color="white"
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="pa-6"
        style="max-height:65vh; height:auto; overflow:auto;"
      >
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-row>
            <v-col cols="6" offset="3" class="py-0">
              <v-text-field
                v-model="billingCustomerId"
                :label="$t('order.billingCustomerId')"
                :rules="requiredRules"
                :disabled="!!billingInfo"
              />
            </v-col>
          </v-row>

          <template v-if="billingInfo">
            <v-row>
              <v-col cols="3" offset="1">
                <label class="font-weight-bold">{{ $t("order.service") }}</label>
                <div>{{ billingInfo.subName }}</div>
              </v-col>

              <v-col cols="3">
                <label class="font-weight-bold">{{ $t("network.billingItemCode") }}</label>
                <div>{{ billingInfo.itemCode }}</div>
              </v-col>

              <v-col cols="4">
                <label class="font-weight-bold">{{ $t("address.address") }}</label>
                <div>{{ formatAddress() }}</div>
              </v-col>
            </v-row>
          </template>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center mb-2">
        <v-btn
          class="primary px-8 mr-4"
          @click.native="onSubmitForm"
        >
          {{ billingInfo ? $t("order.createLink") : $t("order.loadBillingInfo") }}
        </v-btn>

        <v-btn
          v-if="billingInfo"
          class="warning px-8 mr-4"
          @click.native="reset">
          {{ $t('common.reset') }}
        </v-btn>

        <v-btn
          class="error px-8"
          @click.native="dialog = false">
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </base-card>
  </v-dialog>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import util from '@/utils'
  import { showErrorDialog } from '@/utils/swalDialog'

  export default {
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      dialog: {
        get() {
          return this.value
        },
        set(v) {
          this.$emit('input', v)
        }
      },
    },

    data() {
      return {
        billingCustomerId: '',
        billingInfo: null,
        requiredRules: [
          value => !!value || this.$t('error.requiredField'),
        ],
        validForm: true,
      }
    },

    watch: {
      dialog (value) {
        if (!value) {
          return
        }

        this.reset()
      },
    },

    methods: {
      ...mapActions({
        fetchCustomerBillingInfo: 'fetchCustomerBillingInfo',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
      }),

      onSubmitForm() {
        const isValidForm = this.$refs.form.validate()

        if (!isValidForm) {
          return
        }

        if (this.billingInfo) {
          this.createLinkToSubscription()
        } else {
          this.loadBillingInfo()
        }
      },

      async loadBillingInfo() {
        this.setLoading(true)
        this.setLoadingText(`${this.$t('common.loading')} ${this.$t(
          'common.data'
        )}...`)

        try {
          this.billingInfo = await this.fetchCustomerBillingInfo({
            subId: this.billingCustomerId,
          })
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      formatAddress() {
        if (!this.billingInfo) {
          return ''
        }

        return `${this.billingInfo.streetAddress}, ${util.formatPostalCode(this.billingInfo.zipCode)} ${this.billingInfo.city}`
      },

      createLinkToSubscription() {
        this.$emit('create-link', this.billingCustomerId)
      },

      reset() {
        this.billingInfo = null
        this.billingCustomerId = ''
      },
    },
  }
</script>
