<template>
  <base-card>
    <v-card-text>
      <v-row class="align-end  justify-center">
        <v-col
          cols="12"
          sm="6"
          md="2"
          lg="2"
        >
          <v-select
            v-model="filterOptions.network"
            :items="networkList"
            :label="$t('price.network')"
            hide-details
            item-text="item"
            item-value="item"
            clearable
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="3"
          lg="3"
        >
          <v-text-field
            v-model="filterOptions.spReference"
            hide-details
            clearable
            :label="$t('order.spReference')"
          />
        </v-col>
      </v-row>

      <v-row class="align-end">
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-btn
            class="primary px-4 mr-4"
            @click="currentPage = 1; loadSubscriptionList()"
          >
            {{ $t("common.search") }}
          </v-btn>

          <v-btn
            class="error px-4"
            @click="resetFilter">
            {{ $t("common.reset") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="subscriptionList"
        :items-per-page="paginationLimit"
        hide-default-footer
        class="eg-table-bnone eg-table-td-py-md mt-2 px-4"
      >
        <template v-slot:item.index="{item}">
          <v-btn
            small
            color="primary"
            @click.native="onCreateLink(item)"
          >
            {{ $t("order.createLink") }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions class="justify-center">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        color="primary"
        :total-visible="7"
        circle
      />
    </v-card-actions>

    <create-link-subscription-dialog
      v-model="isShowCreateLinkDialog"
      @create-link="createLink"
    />
  </base-card>
</template>

<script>
  import { mapActions } from 'vuex'

  import util from '@/utils'
  import { showSuccessDialog, showErrorDialog } from '@/utils/swalDialog'

  import CreateLinkSubscriptionDialog from './CreateLinkSubscriptionDialog'

  export default {
    metaInfo: {
      title: 'Subscriptions',
    },

    components: {
      CreateLinkSubscriptionDialog,
    },

    data () {
      return {
        token: null,
        isShowUpdateTokenDialog: false,
        subscriptionList: [],
        paginationLimit: 10,
        currentPage: 1,
        totalPages: 0,
        headers: [{
          text: this.$t('common.action'),
          value: 'index',
          width: 80,
        },{
          text: this.$t('price.network'),
          value: 'network',
          width: 140,
        },{
          text: this.$t('order.spReference'),
          value: 'spReference',
          width: 220,
        }, {
          text: this.$t('order.orderId'),
          value: 'orderId',
          width: 140,
        }, {
          text: this.$t('order.accessId'),
          value: 'accessId',
          width: 140,
        }, {
          text: this.$t('order.subscriptionId'),
          value: 'subscriptionId',
          width: 220,
        }, {
          text: this.$t('order.orderInfo'),
          value: 'openNetworkOrderId',
          width: 140,
        }, {
          text: this.$t('order.service'),
          value: 'service',
          width: 180,
        }],
        util: util,
        filterOptions: {
          spReference: '',
          network: '',
        },
        networkList: [
          'OPENINFRA',
          'SOLLENTUNA',
          'ZITIUS'
        ],
        selectedSubscription: null,
        isShowCreateLinkDialog: false,
      }
    },

    watch: {
      currentPage(val) {
        this.currentPage = val
        this.loadSubscriptionList()
      },
    },

    mounted () {
      this.loadSubscriptionList()
    },

    methods: {
      ...mapActions({
        fetchOpenNetworkSubscriptions: 'fetchOpenNetworkSubscriptions',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
        createLinkBetweenSubscriptionAndBilling: 'createLinkBetweenSubscriptionAndBilling',
      }),

      onCreateLink(item) {
        this.selectedSubscription = item
        this.isShowCreateLinkDialog = true
      },

      async createLink(billingCustomerId) {
        this.isShowCreateLinkDialog = false

        this.setLoadingText(this.$t('order.creatingLink'))
        this.setLoading(true)

        try {
          const data = {
            subscriptionId: this.selectedSubscription.id,
            billingCustomerId,
          }

          const response = await this.createLinkBetweenSubscriptionAndBilling(data)

          const text = this.selectedSubscription.orderId ? this.$t('order.orderUpdated') : this.$t('order.orderCreated')
          showSuccessDialog(text)

          this.loadSubscriptionList()
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }

        this.setLoading(false)
      },

      async loadSubscriptionList() {
        const query = {
          spReference: this.filterOptions.spReference,
          network: this.filterOptions.network,
          offset: (this.currentPage - 1) * this.paginationLimit,
          limit: this.paginationLimit,
        }

        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)
        this.setLoading(true)
        try {
          const response = await this.fetchOpenNetworkSubscriptions(query)

          this.totalPages = Math.ceil(response.count / this.paginationLimit)
          this.subscriptionList = response.rows
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      resetFilter() {
        this.filterOptions = {
          spReference: '',
          network: '',
        }

        if (this.currentPage === 1) {
          this.loadSubscriptionList()
          return
        }

        this.currentPage = 1
      },
    },
  }
</script>
